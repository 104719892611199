import withRedux from "next-redux-wrapper";
import { Provider } from "react-redux";
import {
  makeStore,
  setCookiesAccepted,
  setGlobalOrderingDisabled
} from "../src/actions";
import "raf/polyfill";
import React from "react";
import App, { Container } from "next/app";
import Router from "next/router";
import NProgress from "nprogress";
import Head from "next/head";
import NavBar from "../src/components/NavBar";
import Breadcrumbs from "../src/components/common/Breadcrumbs";
import Footer from "../src/components/common/Footer";
import Cookies from "js-cookie";
import Modal from "../src/components/common/Modal";
import TagManager from "react-gtm-module";
import WebViewComms from "../src/utils/WebViewComms";
import Error from "./_error";
import API from "../src/api";
import isEmpty from "lodash/isEmpty";
import CookiesBanner from "../src/components/common/CookiesBanner";
import { PullToRefresh } from "react-js-pull-to-refresh";
import DownloadAppModal from "../src/components/checkout/DownloadAppModal";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

class KukdApp extends App {
  constructor({
    url,
    dispatch // NextJS Page props
  }) {
    super(...arguments);

    this.state = {
      isMobile: false
    };
  }

  static async getInitialProps({ Component, router, ctx }) {
    let pageProps = {};

    if (ctx && ctx.res && ctx.res.statusCode === 404) {
      ctx.res.writeHead(301, { Location: "/" })
      ctx.res.end();
    };

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    // Fetch and populate seo date if it exists
    let seoData = process.env.NODE_ENV !== 'development' ? await API.fetchSEOData(
      isEmpty(pageProps.seoPathnameOverride)
        ? ctx.pathname
        : pageProps.seoPathnameOverride,
      API.getServerURL(ctx.req)
    ) : {}
    pageProps = { ...pageProps, ...seoData };

    let orderingDisabledResponse = await API.isGlobalOrderingDisabled(
      API.getServerURL(ctx.req)
    )
      .then(res => res.data)
      .catch(err => {
        return {
          ordering_disabled: false
        };
      });
    if (!isEmpty(orderingDisabledResponse)) {
      ctx.store.dispatch(
        setGlobalOrderingDisabled(
          orderingDisabledResponse.ordering_disabled,
          orderingDisabledResponse.banners || []
        )
      );
    }

    if (!ctx.store.getState().accountData.cookiesAccepted) {
      let cookiesAccepted = ctx.req
        ? ctx.req.cookies.cookiesAccepted === "true" || false
        : Cookies.get("cookiesAccepted") === "true" || false;
      ctx.store.dispatch(setCookiesAccepted(cookiesAccepted));
    }

    if (!ctx.isServer) {
      if (!isEmpty(window)) window.scrollTo(0, 0);
    }

    return { pageProps };
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    const { router, store } = this.props;

    router.events.on("routeChangeStart", url => {
      NProgress.start();
    });
    router.events.on("routeChangeComplete", () => NProgress.done());
    router.events.on("routeChangeError", () => NProgress.done());

    WebViewComms.init();

    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }

  updateDimensions = () => {
    if (typeof window !== "undefined" && window.innerWidth <= 1024) {
      this.setState({ isMobile: true });
    }
  };

  onRefresh() {
    return new Promise(resolve => {
      setTimeout(resolve, 2000);
      window.scrollTo(0, 0);
      location.reload(true);
    });
  }

  render() {
    const { Component, pageProps, store } = this.props;

    const metaDescription = this.renderDescription(pageProps.description, pageProps)

    try {
      var restaurantName = pageProps.schema.name;
      var restaurantPostcode = pageProps.schema.address.postalCode;
      var restaurantRegion = pageProps.schema.address.addressRegion;
    } catch (error) { }

    if (this.props.router.asPath.indexOf("/menu") !== -1) {

      pageProps.title =
        "Find The Restaurant" +
        " " +
        restaurantName + " " +
        "menu" + " " +
        restaurantPostcode +
        " - Order at Kukd.com";

      pageProps.description =
        "Earn money off your next order when you order from" +
        " " +
        restaurantName +
        " " +
        "-" +
        restaurantPostcode +
        " " +
        "in" +
        " " +
        restaurantRegion +
        " " +
        "at Kukd.com. View the full menu, read customer reviews and more here.";
    } else if (this.props.router.asPath.indexOf("/info") !== -1) {
      pageProps.title =
        "Restaurant Information |" + restaurantName + "| Order Online";
      pageProps.description =
        "See" + " " + restaurantName + "opening times and location";
    } else if (this.props.router.asPath.indexOf("/reviews") !== -1) {
      pageProps.title = "Reviews |" + restaurantName + "| Order Online";
      pageProps.description = "Read" + " " + restaurantName + "s reviews";
    } else if (this.props.router.asPath.indexOf("/book-a-table") !== -1) {
      pageProps.title =
        "Book a Table |" + restaurantName + "| Online Reservations";
      pageProps.description =
        "Book a Table at" +
        " " +
        restaurantName +
        ". Just select a date, time and how many people in your party.";
    }

    return (
      <Container>
        <Head>
          <title>{pageProps.title}</title>
          {this.renderCanonical(pageProps.canonical)}
          {this.renderKeywords(pageProps.keywords)}
          {this.renderSchema(pageProps.schema)}
          {metaDescription}
        </Head>

        <Provider store={store}>
          <>
            <NavBar
              miniNav={pageProps.miniNav || false}
              basketMini={
                typeof pageProps.basketMini === "undefined"
                  ? true
                  : pageProps.basketMini
              }
            />

            {pageProps.breadcrumbs && <Breadcrumbs />}

            {this.state.isMobile ? (
              // <PullToRefresh
              //   pullDownContent={
              //     <div className="pull-down-content">
              //       {/* Pull down to refresh */}
              //     </div>
              //   }
              //   releaseContent={
              //     <div className="pull-refresh-content">
              //       <img
              //         className="page-loader-anim"
              //         src="/static/img/buttonLoadingRipple.svg"
              //       />
              //     </div>
              //   }
              //   refreshContent={
              //     <div className="pull-refresh-content">
              //       <img
              //         className="page-loader-anim"
              //         src="/static/img/buttonLoadingRipple.svg"
              //       />
              //     </div>
              //   }
              //   pullDownThreshold={200}
              //   onRefresh={this.onRefresh}
              //   triggerHeight={200}
              //   startInvisible={true}
              // >
              <>
                <DownloadAppModal />
                <Component {...pageProps} />
                <Footer />
              </>
            ) : (
              // </PullToRefresh>
              <>
                <Component {...pageProps} />
                <Footer />
              </>
            )}
            {/* <ToTopButton /> */}
            <Modal />
            <CookiesBanner />
          </>
        </Provider>
      </Container>
    );
  }

  renderDescription(description, pageProps) {
    switch (description) {
      case "All Cuisines":
        if (pageProps.areaData) {
          return <meta name="description" content={`${description} in ${pageProps.areaData.name}`} />;
        }
        if (!pageProps.cuisineData || Object.keys(pageProps.cuisineData).length === 0) {
          return <meta name="description" content={`Other Cuisine Types`} />;
        }
        if (!pageProps.areaData || Object.keys(pageProps.areaData).length === 0) {
          return <meta name="description" content={`All ${pageProps.cuisineData.name} Cuisines`} />;
        }
        break;

      default:
        if (pageProps.description) {
          return <meta name="description" content={`${pageProps.description}`} />;
        }
        break;
    }
    return null;

  }

  renderKeywords(keywords) {
    return <meta name="keywords" content={keywords} />;
  }

  renderSchema(schema) {
    if (!schema) return null;
    return (
      <script
        type="application/ld+json"
        key="page-schema"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    );
  }

  renderCanonical(canonical) {
    if (!canonical) return null;
    return <link rel="canonical" href={canonical} />;
  }
}
export default withRedux(makeStore)(KukdApp);
