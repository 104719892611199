import { connect } from "react-redux";
import Utils from "../src/utils/Utils";
import PostcodeSearch from "../src/components/common/PostcodeSearch";
import ReOrderBar from "../src/components/reorder/ReOrderBar";
import Link from "../src/components/common/Link";
import React from "react";
import isEmpty from "lodash/isEmpty";
import BannersGlobal from "../src/components/common/BannersGlobal";
import {
	LazyLoadComponent,
	LazyLoadImage,
} from "react-lazy-load-image-component";

class Index extends React.Component {
	static pageConfig = {
		title: "Kukd - Home",
		description: "Welcome to the Kukd Home Page.",
		miniNav: true,
		basketMini: false,
		loader: true,
		schema: {
			"@context": "http://schema.org",
			"@type": "Organization",
			name: "Kukd.com",
			url: "https://www.kukd.com",
			logo: "https://www.kukd.com/static/img/kukd_logo.svg",
			sameAs: [
				"https://www.facebook.com/getkukd/",
				"https://twitter.com/GetKukd",
				"https://www.instagram.com/get_kukd/",
			],
		},
	};

	constructor({
		randomBG,
		url,
		dispatch, // NextJS Page Props
		accountData, // Redux Props
	}) {
		super(...arguments);
	}

	static async getInitialProps({
		store,
		isServer,
		pathname,
		req,
		query,
		asPath,
	}) {
		await Utils.handlePageGetInitialProps(...arguments);

		return {
			...this.pageConfig,
			randomBG: Math.floor(Math.random() * 9 + 1),
		};
	}

	render() {
		return (
			<div className="home">
				<BannersGlobal />

				<div className={"hero bg" + this.props.randomBG}>
					{/* Loads a high res img bg on mobile */}
					<LazyLoadComponent>
						<div
							className={`hero hero-lazy high-res-bg${this.props.randomBG}`}
							style={{ zIndex: 0, position: "absolute" }}
						/>
					</LazyLoadComponent>

					<img
						src="/static/img/kukd_logo.svg"
						alt="kukd logo"
						id="jumbo-logo"
						style={{ zIndex: 1 }}
					/>
					{this.props.accountData.userData &&
					!isEmpty(this.props.accountData.userData) ? (
						<h1 className="search-welcome-text">
							Hi {this.props.accountData.userData.first_name}, Hungry?
						</h1>
					) : (
						<h1 className="search-welcome-text">
							Order a Takeaway or Book a Table
						</h1>
					)}
					<PostcodeSearch />
					<ReOrderBar />
				</div>
				<span className="header-img-bottom" />

				{/* <div className="container-fluid">
          <div className="page-content">
            <div className="refer-a-friend-banner">
              <div className="text-block">
                <h3>Get 20% reward for every friend you refer!</h3>
                <p>
                  We’re making it easier for you and your friends, to get out
                  there and make an impact for all the Food Lovers!
                </p>
              </div>
              <div className="button">
                <Link pageName="refer-a-friend">
                  <a className="read-more-btn">Refer Your Friends</a>
                </Link>
              </div>
            </div>
            <div className="recommend-restaurant-banner">
              <div className="text-block">
                <h3>
                  Recommend restaurants and get 20% OFF when they go live!
                </h3>
                <p>
                  If you can't find the restaurant that you want to order from,
                  request to add to our list.
                </p>
              </div>
              <div className="button">
                <Link pageName="recommend-restaurant">
                  <a className="read-more-btn">Start Recommending</a>
                </Link>
              </div>
            </div>
          </div>
        </div> */}

				<div className="details">
					<div className="item">
						<Link pageName="kukd-points">
							<a>
								<LazyLoadComponent>
									<div id="kukd-points">
										<span className="kp-headline">
											Love to eat as much as we do?
										</span>
										<span className="kp-subheadline">
											Start reaping the&nbsp;
											<strong>rewards!</strong>
										</span>
										<span className="kp-big">Kukd Points</span>
										<span className="kp-scheme">Loyalty Scheme!</span>
										<span className="kp-text">
											Our exclusive reward programme allows you to collect
											points every time you order a takeaway or book a table
											online with us at KUKD.com
										</span>
									</div>
								</LazyLoadComponent>
							</a>
						</Link>
						<Link pageName="kukd-points">
							<a className="read-more-btn">Read More About Kukd Points</a>
						</Link>
					</div>
					<div className="item">
						<LazyLoadComponent>
							<div id="mobile-apps">
								<img src="static/img/mobileAppsMain.svg" />
								<span className="ma-headline">Download the Kukd App Today</span>
								<br />
								<span
									className="ma-big"
									style={{ marginTop: "2px !important", display: "block" }}
								>
									Order food online now with the free KUKD app!
								</span>
								<br />
								<span className="ma-scheme" />
								<div className="mobile-apps-img">
									<Link href="https://itunes.apple.com/gb/app/kukd-takeaway-food-delivery-and-reservations/id1052050079">
										<a target="_blank" rel="noopener">
											<LazyLoadImage
												className="apple"
												src="/static/img/apple_appstore.svg"
												alt="apple app store"
											/>
										</a>
									</Link>
									<Link href="https://play.google.com/store/apps/details?id=com.kukd">
										<a target="_blank" rel="noopener">
											<LazyLoadImage
												src="/static/img/google_playstore.svg"
												alt="google play store"
											/>
										</a>
									</Link>
								</div>
							</div>
						</LazyLoadComponent>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		accountData: state.accountData,
	};
};

export default connect(
	mapStateToProps,
	null
)(Index);
