import React from "react";
import { connect } from "react-redux";
import Router from "next/router";
import AppRoutes from "../../appRoutes";
import Link from "./common/Link";
import DropMenuKukd from "./common/DropMenuKukd";
import BasketMini from "./basket/BasketMini";
import { setCookiesAccepted, displayFullscreenBasket } from "../actions";
import isEmpty from "lodash/isEmpty";
import Cookies from "js-cookie";
import { slide as Menu } from "react-burger-menu";
import WebViewComms from "../utils/WebViewComms";
import Utils from "../utils/Utils";

class NavBar extends React.Component {
  constructor({
    miniNav,
    basketMini,
    routePath, //Passed in props
    accountData,
    whitelabelData, // State props
    setCookiesAccepted // State dispatchers
  }) {
    super(...arguments);

    this.state = {
      menuOpen: false,
      isRestaurantPage: this.isRestaurantPage()
    };

    this.acceptCookies = this.acceptCookies.bind(this);
    this.gotoLoginPage = this.gotoLoginPage.bind(this);
    this.gotoAccountPage = this.gotoAccountPage.bind(this);
    this.gotoLogoutPage = this.gotoLogoutPage.bind(this);
  }

  acceptCookies() {
    Cookies.set("cookiesAccepted", true);
    this.props.setCookiesAccepted(true);
  }
  gotoLoginPage() {
    Router.push(AppRoutes.getRoute("login").href);
  }
  gotoAccountPage() {
    Router.push(AppRoutes.getRoute("account").href);
  }
  gotoLogoutPage() {
    Router.push(AppRoutes.getRoute("logout").href);
  }

  handleSideMenuStateChange = state => {
    this.setState({ menuOpen: state.isOpen });
  };

  closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  render() {
    let headerClass = "fixed-top navbar";
    let headerMiniClass = this.props.miniNav ? "navbar-mini" : "";
    let whitelabelHeaderClass =
      this.props.whitelabelData.isWhitelabel && this.isRestaurantPage()
        ? "navbar-whitelabel"
        : "";

    return (
      <header
        className={
          headerClass + " " + headerMiniClass + " " + whitelabelHeaderClass
        }
      >
        <div className="container-limit">
          {this.renderNavLeft()}

          {this.renderBurgerMenu()}

          <div className="nav-tabs">
            {this.recommendRestaurant()}
            {this.renderReferAFriend()}
            {this.renderAccountSection()}
            {this.props.children}
          </div>
        </div>
        {/* {this.renderCookiesMessage()} */}
      </header>
    );
  }

  renderNavLeft() {
    if (this.props.whitelabelData.isWhitelabel && this.isRestaurantPage()) {
      var bodyElement = null;
      if (typeof document !== "undefined") {
        bodyElement = document.getElementsByTagName("BODY")[0];
      }
      return (
        <div className="wl-menu">
          {this.props.whitelabelData.menuItems.map(menuItem => {
            let route = menuItem.url === "/" ? "/" : `/${menuItem.url}`;
            return (
              <Link
                key={menuItem.name}
                href={`https://sites.kukd.com/${this.props.whitelabelData
                  .restaurantURL + route}`}
              >
                <a
                  style={{
                    color: "#fff",
                    textShadow: "0px 0px 15px #000000",
                    paddingTop: "20px",
                    paddingRight: "20px",
                    fontSize: "10pt"
                  }}
                >
                  {Utils.camelCase(menuItem.name)}
                </a>
              </Link>
            );
          })}
        </div>
      );
    } else {
      return (
        <>
          <Link pageName="index">
            <a style={{ height: "100%" }}>
              <img
                className="logo"
                src="/static/img/kukd_logo.svg"
                alt="kukd logo"
              />
            </a>
          </Link>

          <a
            onClick={() => {
              if (this.props.basketData.displayFullscreenBasket) {
                this.props.displayFullscreenBasket(false);
                bodyElement && bodyElement.classList.remove("noScroll");
              } else {
                Router.back();
                bodyElement && bodyElement.classList.remove("noScroll");
              }
            }}
          >
            <img
              className="back-button"
              src="/static/img/icons/arrow-left.svg"
            />
          </a>
        </>
      );
    }
  }

  renderBurgerMenu() {
    return (
      <div id="outer-container">
        <Menu
          isOpen={this.state.menuOpen}
          customBurgerIcon={<img src="/static/img/icons/burger-menu.svg" />}
          outerContainerId={"outer-container"}
          onStateChange={this.handleSideMenuStateChange}
          right
        >
          {this.props.accountData.loggedIn &&
          !isEmpty(this.props.accountData.userData)
            ? this.renderLoggedInBurgerMenu()
            : this.renderLoggedOutBurgerMenu()}
        </Menu>
      </div>
    );
  }

  renderLoggedInBurgerMenu() {
    return (
      <div className="d-flex flex-column">
        {this.renderBurgerMenuWhiteLabelItems()}
        <Link pageName="index">
          <a id="home" className="menu-item" onClick={this.closeMenu}>
            <img
              src="/static/img/icons/find-restaurants.svg"
              alt="Find Restaurants"
            />{" "}
            Find Restaurants
          </a>
        </Link>
        <Link pageName="account">
          <a id="account" className="menu-item" onClick={this.closeMenu}>
            <img src="/static/img/icons/account.svg" alt="My Account" /> My
            Account
          </a>
        </Link>
        <Link pageName="orders">
          <a id="orders" className="menu-item" onClick={this.closeMenu}>
            <img src="/static/img/icons/order.svg" alt="My Orders" /> My Orders
          </a>
        </Link>
        <Link pageName="reservations">
          <a id="reservations" className="menu-item" onClick={this.closeMenu}>
            <img
              src="/static/img/icons/reservation.svg"
              alt="My Reservations"
            />{" "}
            My Reservations
          </a>
        </Link>
        <Link pageName="wallet">
          <a id="wallet" className="menu-item" onClick={this.closeMenu}>
            <img src="/static/img/icons/wallet.svg" alt="My Wallet" /> My Wallet
          </a>
        </Link>
        <Link pageName="points">
          <a id="kukdpoints" className="menu-item" onClick={this.closeMenu}>
            <img src="/static/img/icons/kukdpoints.svg" alt="My Kukd Points" />{" "}
            My Kukd Points
          </a>
        </Link>
        <Link pageName="referrals">
          <a id="refer" className="menu-item" onClick={this.closeMenu}>
            <img
              src="/static/img/icons/refer-a-friend.svg"
              alt="My Referrals"
            />{" "}
            My Referrals
          </a>
        </Link>
        <Link pageName="recommend-restaurant">
          <a id="recommend" className="menu-item" onClick={this.closeMenu}>
            <img src="/static/img/icons/reccomend-restaurant.svg" /> Reccomend Restaurant
          </a>
        </Link>
        <Link pageName="logout">
          <a
            id="logout"
            className="menu-item"
            onClick={() => {
              WebViewComms.logoutClicked();
              this.closeMenu();
            }}
          >
            <img src="/static/img/icons/logout.svg" alt="Logout" /> Logout
          </a>
        </Link>
      </div>
    );
  }

  renderLoggedOutBurgerMenu() {
    return (
      <div className="d-flex flex-column">
        {this.renderBurgerMenuWhiteLabelItems()}
        <Link pageName="index">
          <a id="home" className="menu-item" onClick={this.closeMenu}>
            <img src="/static/img/icons/find-restaurants.svg" /> Find
            Restaurants
          </a>
        </Link>
        <Link pageName="login">
          <a id="login" className="menu-item" onClick={this.closeMenu}>
            <img src="/static/img/icons/signup.svg" /> Login or Signup
          </a>
        </Link>
        <Link pageName="referrals">
          <a id="refer" className="menu-item" onClick={this.closeMenu}>
            <img src="/static/img/icons/refer-a-friend.svg" /> Refer Friends
          </a>
        </Link>
        <Link pageName="recommend-restaurant">
          <a id="refer" className="menu-item" onClick={this.closeMenu}>
            <img src="/static/img/icons/reccomend-restaurant.svg" /> Recommend Restaurant
          </a>
        </Link>
      </div>
    );
  }

  renderBurgerMenuWhiteLabelItems() {
    return this.props.whitelabelData.menuItems.map(menuItem => {
      let route = menuItem.url === "/" ? "/" : `/${menuItem.url}`;
      return (
        <Link
          key={menuItem.name}
          href={`https://sites.kukd.com/${this.props.whitelabelData
            .restaurantURL + route}`}
        >
          <a id="home" className="menu-item" onClick={this.closeMenu}>
            {/* <span className="fas fa-home mr-4" style={{ color: "white" }} /> */}
            {Utils.camelCase(menuItem.name)}
          </a>
        </Link>
      );
    });
  }

  renderReferAFriend() {
    if (
      this.props.routePath.currentRoutePath === "/refer-a-friend" ||
      (this.props.whitelabelData.isWhitelabel && this.isRestaurantPage())
    ) {
      return;
    }

    return (
      <div
        className="refer-a-friend-btn"
        onClick={e => {
          Router.push(AppRoutes.getRoute("refer-a-friend").href);
        }}
        title="Refer your friends"
      >
        <span className="fas fa-user-plus" /> &nbsp;Refer your friends
      </div>
    );
  }
  recommendRestaurant() {
    if (
      this.props.routePath.currentRoutePath === "/recommend-restaurant" ||
      (this.props.whitelabelData.isWhitelabel && this.isRestaurantPage())
    ) {
      return;
    }

    return (
      <div
        className="reccomend-restaurant-btn"
        onClick={e => {
          Router.push(AppRoutes.getRoute("recommend-restaurant").href);
        }}
        title="Recommend a restaurant"
      >
        <span className="icon fas fa-star"/> &nbsp;Recommend a restaurant
      </div>
    );
  }

  renderAccountSection() {
    return (
      <>
        <div className="user-tab">
          {this.props.accountData.loggedIn &&
          !isEmpty(this.props.accountData.userData)
            ? this.renderTabLoggedIn()
            : this.renderTabLoggedOut()}
        </div>

        {this.props.basketMini && <BasketMini />}
      </>
    );
  }

  renderTabLoggedIn() {
    return (
      <DropMenuKukd
        id="navSelect"
        initialSelector={
          <>
          <span className="icon fas fa-user"></span>
            <span className="non-mobile">
              &nbsp;Account
            </span>
          </>
        }
        options={[
          { text: "My Account", onClick: this.gotoAccountPage },
          { text: "Log Out", onClick: this.gotoLogoutPage }
        ]}
      />
    );
  }

  renderTabLoggedOut() {
    return (
      <div className="logged-out-user">
        <span className="fas fa-user" />
        &nbsp;
        <a onClick={this.gotoLoginPage}>Login</a>
        &nbsp;or&nbsp;
        <Link pageName="register">
          <a>Sign up</a>
        </Link>
      </div>
    );
  }

  renderCookiesMessage() {
    if (this.props.accountData.cookiesAccepted) return null;

    return (
      <div className="item d-flex flex-row justify-content-right cookie-message">
        <p className="item">
          Cookies help us deliver the best possible online service to you. By
          using our services or clicking "Accept Cookies", you agree to our use
          of cookies. &nbsp;
          <Link pageName="privacyPolicy">
            <a>Privacy Policy</a>
          </Link>
        </p>
        <button
          type="button"
          value="accept"
          className="item accept-button"
          onClick={this.acceptCookies}
        >
          Accept Cookies
        </button>
      </div>
    );
  }

  isRestaurantPage() {
    let isRestaurantPage = false;
    if (this.props.routePath.currentRoutePath[0] === "/") {
      let minusSlash = this.props.routePath.currentRoutePath.substr(1);
      if (minusSlash.split("/")[0] === "restaurant") {
        isRestaurantPage = true;
      }
    }
    return isRestaurantPage;
  }
}

const mapStateToProps = state => {
  return {
    accountData: state.accountData,
    routePath: state.routePath,
    whitelabelData: state.whitelabelData,
    basketData: state.basketData
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setCookiesAccepted: val => {
      dispatch(setCookiesAccepted(val));
    },
    displayFullscreenBasket: display => {
      dispatch(displayFullscreenBasket(display));
    }
  };
};

export default (NavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBar));
